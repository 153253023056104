import { ReactElement } from 'react';
import PageWidth from 'styles/components/PageWidth';
import Image from 'components/Image/ResponsiveImage';
import {
  BlockTitle,
  BlockSubtitle,
  BlockWrapper,
} from 'styles/components/Title';

const AsSeenIn = (): ReactElement => (
  <PageWidth>
    <a
      className="relative hover:scale-up"
      href="https://www.facebook.com/watch/live/?v=3365502626839829&ref=watch_permalink"
      target="__blank"
    >
      <div className="h-96 p-5 relative overflow-hidden">
        <Image
          src="https://cdn.shopify.com/s/files/1/0019/7060/2050/files/Paper_flowers_shoot.jpeg?v=1599922197"
          alt="TimeOut London video shoot image"
        />

        <BlockWrapper>
          <BlockTitle>As seen in Time Out London & Old El Paso</BlockTitle>
          <BlockSubtitle>
            Learn how to make authentic Mexican paper flowers!
          </BlockSubtitle>
        </BlockWrapper>
      </div>
    </a>
  </PageWidth>
);

export default AsSeenIn;
