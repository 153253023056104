import { ReactElement, ReactNode } from 'react';
import client from 'shopify/shopify';
import dynamic from 'next/dynamic';
import Hero from 'components/Hero/Hero';
import AsSeenIn from 'components/AsSeenIn/AsSeenIn';
// import NewestBox from 'components/Home/NewestBox/NewestBox';

const WhatsInTheBox = dynamic(
  () => import('components/Home/WhatsInTheBox/WhatsInTheBox'),
);
const WhatMakesUsSpecial = dynamic(
  () => import('components/Home/WhatMakesUsSpecial/WhatMakesUsSpecial'),
);
const HomeCollection = dynamic(
  () => import('components/Home/HomeCollection/HomeCollection'),
);
const AsFeaturedOn = dynamic(
  () => import('components/Home/AsFeaturedOn/AsFeaturedOn'),
);
const CraftBoxPromo = dynamic(
  () => import('components/Home/CraftBoxPromo/CraftBoxPromo'),
);

const Section = ({ children }: { children: ReactNode }): ReactElement => (
  <div className="mb-12">{children}</div>
);

const HomeContainer = ({
  craftBoxes,
  partyBags,
}: HomeContainerProps): ReactElement => (
  <div className="flex flex-col w-full">
    <Hero />

    <Section>
      <CraftBoxPromo />
    </Section>

    <Section>
      <WhatsInTheBox />
    </Section>

    <Section>
      <HomeCollection collection={craftBoxes} />
    </Section>

    <Section>
      <WhatMakesUsSpecial />
    </Section>

    <Section>
      <HomeCollection collection={partyBags} />
    </Section>

    <Section>
      <AsSeenIn />
    </Section>

    <Section>
      <AsFeaturedOn />
    </Section>
  </div>
);

export async function getStaticProps(): Promise<{
  props: { craftBoxes: Collection | null; partyBags: Collection | null };
}> {
  const collections = await client.collection.fetchAllWithProducts();

  const craftBoxes = collections.find(
    collection => collection.handle === 'craft-boxes',
  );
  const partyBags = collections.find(
    collection => collection.handle === 'party-bags',
  );

  const craftBoxesCollection = await client.collection.fetchWithProducts(
    craftBoxes?.id,
    {
      productsFirst: 6,
    },
  );

  const partyBagsCollection = await client.collection.fetchWithProducts(
    partyBags?.id,
    {
      productsFirst: 6,
    },
  );

  return {
    props: {
      craftBoxes: JSON.parse(JSON.stringify(craftBoxesCollection)) || null,
      partyBags: JSON.parse(JSON.stringify(partyBagsCollection)) || null,
    },
  };
}

type HomeContainerProps = {
  craftBoxes: Collection;
  partyBags: Collection;
};

export default HomeContainer;
