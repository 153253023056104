import { ReactElement } from 'react';
import NextImage, { ImageLoaderProps } from 'next/image';

const ResponsiveImage = ({
  src,
  alt,
  ...props
}: ResponsiveImageProps): ReactElement => (
  <NextImage
    className="rounded-md"
    src={src}
    alt={alt}
    layout="fill"
    objectFit="cover"
    {...props}
  />
);

type ResponsiveImageProps = {
  src: string | StaticImageData;
  alt: string;
  priority?: boolean;
  loader?: ({ src, width }: ImageLoaderProps) => string;
};

export default ResponsiveImage;
