import { ReactElement } from 'react';
import Link from 'next/link';
import PageWidth from 'styles/components/PageWidth';
import {
  BlockTitle,
  BlockSubtitle,
  BlockWrapper,
} from 'styles/components/Title';
import Image from 'components/Image/ResponsiveImage';
import HalloweenBox from 'public/halloween-box.jpeg';

const Hero = (): ReactElement => (
  <Link href="/product/halloween-box">
    <div className="relative h-96 md:h-heroMd -mb-5 md:-mb-14 cursor-pointer">
      <Image src={HalloweenBox} alt="Halloween Craft Kit" priority />

      <PageWidth>
        <BlockWrapper className="mb-10 md:mb-24">
          <BlockTitle tag="h1">
            Our Halloween Craft Kit is back by popular demand!
          </BlockTitle>
          <BlockSubtitle tag="h2">Featuring 4 spooky crafts! 👻</BlockSubtitle>
        </BlockWrapper>
      </PageWidth>
    </div>
  </Link>
);

export default Hero;
